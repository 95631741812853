.wrapper {
    margin: 0 20px 60px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon {
    width: 50px;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    max-width: 600px;
}

.desc {
    font-size: 20px;
    margin: 0;
    text-align: justify;
}
