@import "src/Static/Styles/_globals.scss";

.wrapper {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header {
    text-align: center;
    font-size: 35px;
    margin: 0 -110px 0 0;

    @media screen and (max-width: $size-mobile) {
        margin-right: -80px;
    }
}
