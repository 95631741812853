@import "src/Static/Styles/_globals.scss";

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;

    @media screen and (max-width: $size-mobile) {
        width: 100%;
    }
}

.heading {
    margin: 0;

    @media screen and (max-width: $size-mobile) {
        display: none;
    }
}
