@import "src/Static/Styles/_globals.scss";

.user {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 5px 10px 5px 5px;

    background-color: $color-other-message;
    padding: 10px;
    border-radius: 10px;
}

.userMe {
    @extend .user;
    background-color: $color-my-message;
}

.name {
    align-self: start;
    margin: 0;
}

.uuid {
    align-self: end;
    font-size: 12px;
    font-weight: bold;
    margin: 0;
    font-family: $font-family-code;
}
