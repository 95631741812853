@import "src/Static/Styles/_globals.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    height: calc(100vh - 370px);
}

.iconWrapper {
    width: 40px;
    height: 40px;
}

.icon {
    width: 100%;
}

.text {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin: 0;
}
