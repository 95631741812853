@import "src/Static/Styles/_globals.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    overflow-x: hidden;
}

.headerWrapper {
    display: flex;
    width: calc(100% - 30px);
    justify-content: space-between;
}

.headerWrapperOne {
    @extend .headerWrapper;
    justify-content: flex-end;
}

.header {
    font-size: 28px;
    text-align: center;
    margin: 0;
}

.listWrapper {
    padding: 10px;
    height: calc(100vh - 300px);
    width: 100%;
    gap: 15px;

    @media screen and (max-width: $size-mobile) {
        height: calc(100vh - 260px);
        padding: 20px;

        @media screen and (max-height: $size-keyboard) {
            height: calc(100vh - 160px);
        }
    }
}

.list {
    display: flex;
    flex-direction: column;
    align-self: center;
    overflow-x: hidden;
}

.followButton {
    display: none;
}
