@import "src/Static/Styles/globals";

.form {
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 40px;
    padding: 0 10px;
}

.textArea {
    resize: none;
    font-family: $font-family-text;
    color: $color-text;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    border: 0;
    background-color: $color-accent;
    font-weight: 600;

    @media screen and (max-width: $size-mobile) {
        width: calc(100% - 20px);
    }

    &::placeholder {
        font-family: $font-family-text;
        color: $color-text-light;
        font-weight: 600;
        font-size: 16px;
    }

    &:focus {
        outline: none;
    }
}

.submit {
    font-family: $font-family-text;
    border-radius: 5px;
    border: 0;
    padding: 10px;
    height: fit-content;
    width: 100%;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    background-color: $color-accent;

    @media screen and (max-width: $size-mobile) {
        display: none;
    }

    &:hover {
        cursor: pointer;
    }
}
