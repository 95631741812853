@import "src/Static/Styles/_globals.scss";

.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.ghost {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.ghostClickable {
    @extend .ghost;
    &:hover {
        cursor: pointer;
    }

    &:hover {
        .refreshWrapper {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.desc {
    margin: 30px 0 0 0;
    font-size: 20px;
    font-weight: 500;

    @media screen and (max-width: $size-mobile) {
        margin: 20px;
    }
}

.refreshWrapper {
    opacity: 0;
    transition: 0.3s;
    transform: translateY(50px);
    display: flex;
    gap: 5px;
}
