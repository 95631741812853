.wrapper {
    align-self: flex-end;
}

.header {
    display: flex;
    height: 41px;
    padding: 0 10px;

    justify-content: space-between;
    align-items: center;
}

.heading {
    margin: 0;
    padding: 10px;
    font-size: 24px;
}
