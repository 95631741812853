@import "src/Static/Styles/_globals.scss";

.wrapper {
    margin: 15px 0;
}

.messages {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 300px);
    width: 100%;

    @media screen and (max-width: $size-mobile) {
        height: calc(100vh - 340px);

        @media screen and (max-height: $size-keyboard) {
            height: calc(100vh - 220px);
        }
    }
}

.scrollButton {
    display: none;
}

.spinnerWrapper {
    height: calc(100vh - 270px);

    @media screen and (max-width: $size-mobile) {
        height: calc(100vh - 310px);

        @media screen and (max-height: $size-keyboard) {
            height: calc(100vh - 190px);
        }
    }
}
