.button {
    margin: 0;
    padding: 0;
    border: 0;
    width: 40px;
    height: 40px;
    background-color: transparent;
    transition: 300ms;
    align-self: center;

    &:hover {
        cursor: pointer;
    }
    &:active {
        transform: scale(0.9);
    }
}

.buttonBig {
    @extend .button;
    width: 60px;
    height: 60px;
    margin: 10px;
}

.buttonIcon {
    height: 100%;
}
