@import "src/Static/Styles/_globals.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;

    @media screen and (max-width: $size-mobile) {
        display: none;
    }
}

.hint {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.name {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.nameLabel {
    display: none;
}

.nameInput {
    margin: 0;
    font-family: $font-family-text;
    text-align: right;
    background-color: transparent;
    color: $color-text;
    outline: none;
    border: 0;
    font-size: 20px;
    opacity: 1;
    padding: 0;
    width: 100%;

    @media screen and (max-width: $size-mobile) {
        width: 100%;
    }
}
