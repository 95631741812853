.listWrapper {
    max-height: 400px;
    padding: 10px;
}

.list {
    padding: 10px;
}

.followButton {
    display: none;
}
