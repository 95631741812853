@import "src/Static/Styles/globals";

.message {
    padding: 10px;
    max-width: 400px;
    margin: 10px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.myMessage {
    @extend .message;
    align-self: flex-end;
    background-color: $color-my-message;
    border-radius: 10px 0 10px 10px;
}

.otherMessage {
    @extend .message;
    align-self: flex-start;
    background-color: $color-other-message;
    border-radius: 0 10px 10px 10px;
}

.body {
    margin: 0;
    overflow-wrap: anywhere;
}

.user {
    font-size: 12px;
    align-self: flex-end;
    font-weight: 600;
    margin: 10px 0 0;
}

.date {
    margin: 0;
    font-size: 14px;
    align-self: flex-end;
}
