@import "src/Static/Styles/_globals.scss";

.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-right: 10px;
}

.navigation {
    display: flex;
    gap: 10px;
}

.nameWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
}

.heading {
    font-size: 24px;
    margin: 0;
}

.hint {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}
