@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

$font-family-text: "Roboto Slab", serif;
$font-family-code: "Roboto Mono", monospace;

$color-room: #ffffff36;
$color-room-picker: #ffffff66;
$color-accent: #ffffffaa;

$color-drawing-board: #ffffff55;

$color-text: #000000cc;
$color-text-light: #00000066;

$color-my-message: #ffffff88;
$color-other-message: #ffffff36;

$size-compact: 1400px;
$size-limited: 1200px;
$size-mobile: 600px;
$size-keyboard: 600px;

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: $color-accent;
}

body {
    font-family: $font-family-text;
    margin: 0;

    color: $color-text;
    min-height: 100vh;
}

a:link,
a:visited,
a:hover,
a:active {
    text-decoration: none;
    color: $color-text;
}
