@import "src/Static/Styles/_globals.scss";

.body {
    height: 100vh;
}

.bodyFixed {
    min-height: 100vh;
}

.container {
    height: 100%;
    padding: 0 20px;
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-rows: 150px 1fr;

    @media screen and (max-width: $size-limited) {
        max-width: 800px;
    }
    @media screen and (max-width: $size-mobile) {
        padding: 0 10px;
        grid-template-rows: 90px 1fr;
    }
}
