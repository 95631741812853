@import "src/Static/Styles/_globals.scss";

.item {
    border-left: 2px solid #ffffff55;
    position: relative;
    padding: 0 0 30px 30px;
    margin-left: 10px;
    font-family: $font-family-code;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    &:before {
        content: "";
        width: 15px;
        height: 15px;
        background: #ffffffdd;
        box-shadow: 3px 3px 0 $color-accent;
        border-radius: 50%;
        position: absolute;
        left: -10px;
        top: -2px;
    }
}

.lastItem {
    @extend .item;
    border: 0;
    padding-bottom: 0;
    padding-left: 32px;
}

.origin {
    margin: 0;
    font-size: 14px;
}

.time {
    margin: 0;
}

.type {
    margin: -10px 0 0 0;
    align-self: flex-end;
}

.description {
    margin: 0;
    align-self: flex-end;
}
