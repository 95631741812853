.wrapper {
    padding: 0 10px;
}

.header {
    display: flex;
    padding-left: 10px;
    justify-content: space-between;
    align-items: center;
}
